import React from 'react';

import { useAuth0 } from "./react-auth0-spa";

function HelloWorld() {
  const { user } = useAuth0();

  return (
    <div className="HelloWorld">
      <h1>Hello world! User: {user.name}</h1>
    </div>
  );
}

export default HelloWorld;
