import React from 'react';
import HelloWorld from "./HelloWorld";
import './App.css';

import { useAuth0 } from "./react-auth0-spa";

function App() {
  const { loading, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  if (loading) {
    return <h1>Loading</h1>;
  }

  return (
    <div className="App">
      {!isAuthenticated && (<button onClick={() => loginWithRedirect({})}>Log in</button>)}
      {isAuthenticated && (
        <div>
          <button onClick={() => logout({ returnTo: window.location.origin })}>Log out</button>
          <HelloWorld />
        </div>)}
    </div>
  );
}

export default App;
